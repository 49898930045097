import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { PageSectionWrapper } from "../components/common";
import { FilmPageHeader, FilmPageVideos } from "../components/FilmPage";

export const FilmPageTemplate = ({ title, filmPage, filmVideos }) => {
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper>
        <FilmPageHeader filmPage={filmPage} />
        <FilmPageVideos filmVideos={filmVideos} />
      </PageSectionWrapper>
    </>
  );
};

const FilmPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <FilmPageTemplate 
      title={frontmatter.title} 
      filmPage={frontmatter.filmPage} 
      filmVideos={frontmatter.filmVideos} 
    />
  );
};

export default FilmPage;

export const filmPageQuery = graphql`
  query FilmPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        filmPage {
          heading
          body
        }
        filmVideos {
          filmVideoSrc
          filmVideoTitle
        }
      }
    }
  }
`;
