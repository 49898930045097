import React from "react";
import styled from "styled-components";
import { respondTo } from "../../styles";

import {
  PageSection,
  PageSectionBackdrop,
  PageSectionArticle,
} from "../common";

const FilmPageVideosWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  ${respondTo.lg`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const FilmPageVideo = styled.div`
  margin-bottom: 30px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;

  // ${respondTo.sm`
  //   flex-flow: column wrap;
  // `}


  iframe {
    width: 300px;
    height: auto;
    margin-bottom: 20px;

    ${respondTo.sm`
      width: 480px;
      height: 260px;
    `}
  }
`;

export const FilmPageVideos = ({ filmVideos }) => {
    return (
    <PageSection bgColor="var(--creamWhite)">
        <PageSectionBackdrop bgColor="var(--creamWhite)">
            <PageSectionArticle>
                <FilmPageVideosWrapper>
                    {!!filmVideos && 
                    filmVideos.map(({ filmVideoTitle, filmVideoSrc }, i) => (
                    <FilmPageVideo key={i}>
                      <iframe
                      key={i}
                      src={filmVideoSrc}
                      title={filmVideoTitle}
                      frameBorder="0"
                      // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      ></iframe>
                      <span>{filmVideoTitle}</span>
                    </FilmPageVideo>
                ))}
                </FilmPageVideosWrapper>
            </PageSectionArticle>
        </PageSectionBackdrop>
    </PageSection>
            );
};